import cn from "../utils/cn";
import * as React from "react";

function SelectTier(props) {
  return (
    <div className="flex lg:flex-row flex-col gap-4">
      {pricing.tiers.map((tier) => (
        <div
          key={tier.id}
          className={cn(
            props.state.selected_tier === tier.id
              ? "ring-2 ring-indigo-600"
              : "ring-1 ring-gray-200",
            "rounded-3xl p-8 flex-1 flex flex-col justify-between",
          )}
        >
          <div>
            <h2
              id={tier.id}
              className={cn(
                tier.mostPopular ? "text-indigo-600" : "text-gray-900",
                "text-lg/8 font-semibold",
              )}
            >
              {tier.name}
            </h2>
            <p className="mt-4 text-sm/6 text-gray-600">{tier.description}</p>
          </div>
          <div>
            <p className="mt-6 flex items-baseline gap-x-1">
              <span className="text-4xl font-semibold tracking-tight text-gray-900">
                {tier.price}
              </span>
            </p>
            <button
              onClick={props.handleChange}
              name="selected_tier"
              value={tier.id}
              aria-describedby={tier.id}
              className={cn(
                "bg-blue-600 text-white shadow-sm hover:bg-indigo-500",
                "mt-6 block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full",
              )}
            >
              Select
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
const pricing = {
  tiers: [
    {
      name: "All Medical Professionals (MD, RN, DO, etc)",
      id: "MEDICAL",
      href: "#",
      price: "$1599",
      eb_price: "$999",
      description: "",
    },
    {
      name: "Estheticians, Permanent Make up Artist, Medical Assistants, Practice Management Team",
      id: "OTHER",
      href: "#",
      price: "$1199",
      eb_price: "$699",
      description: "",
    },
  ],
};

export default SelectTier;
